import React, { useState, useEffect } from 'react';
import Wishlist from './Wishlist';
import api from '../api';
import { getOrCreateUserId } from '../utils/cookieUtils';

function Main() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState('');
    const userId = getOrCreateUserId();

    // Function to fetch items
    const fetchItems = async () => {
        try {
            const fetchedItems = await api.fetchWishlistItems(userId);
            setItems(fetchedItems);
        } catch (err) {
            setError('Ошибка при загрузке вишлиста:\n\n' + err);
        }
    };

    // useEffect to initially fetch the items
    useEffect(() => {
        fetchItems();
    }, []);

    // onAction function to be called when an action is performed in WishlistItem
    const onAction = () => {
        fetchItems(); // Re-fetch items to update the UI
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <Wishlist items={items} onAction={onAction} isAdmin={false} userId={userId} />
    );
}

export default Main;
