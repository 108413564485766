import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';

function LoginForm() {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [loginError, setLoginError] = useState('');
    const history = useHistory();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.login(credentials); // Your API call for login
            if (response.access_token) {
                history.push('/admin');
            } else {
                setLoginError('Invalid username or password');
            }
        } catch (error) {
            setLoginError('Login failed. Please try again.');
        }
    };

    return (
        <div className="login-form">
            <form onSubmit={handleSubmit}>
                <input 
                    name="username" 
                    value={credentials.username} 
                    onChange={handleChange} 
                    placeholder="Логин"
                    className="login-input"
                />
                <input 
                    name="password" 
                    type="password" 
                    value={credentials.password} 
                    onChange={handleChange} 
                    placeholder="Пароль"
                    className="login-input"
                />
                <button type="submit" className="login-button">Login</button>
                {loginError && <div className="error-message">{loginError}</div>}
            </form>
        </div>
    );
}

export default LoginForm;
