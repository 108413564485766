import React, { useState } from 'react';
import api from '../api';

function AddItemForm({ onItemAdded }) {
    const [itemData, setItemData] = useState({
        name: '',
        picture_url: '',
        description: '',
        price: '',
        buy_link: ''
    });
    const [formError, setFormError] = useState('');

    const handleChange = (e) => {
        setItemData({ ...itemData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert empty strings to null
        const payload = Object.fromEntries(
            Object.entries(itemData).map(([key, value]) => [key, value || null])
        );

        try {
            const response = await api.addItem(payload); // Your API call to add an item
            if (response) {
                onItemAdded();
                setItemData({ name: '', picture_url: '', description: '', price: '', buy_link: '' }); // Reset form
            } else {
                setFormError('Не получилось добавить. Возможно проблемы с сетью.');
            }
        } catch (error) {
            setFormError('Error: ' + error.message);
        }
    };

    return (
        <div className="add-item-form">
            <form onSubmit={handleSubmit}>
                <input 
                    name="name" 
                    value={itemData.name} 
                    onChange={handleChange} 
                    placeholder="Название"
                />
                <textarea 
                    name="description" 
                    value={itemData.description} 
                    onChange={handleChange} 
                    placeholder="Описание"
                />
                <input 
                    name="picture_url" 
                    value={itemData.picture_url} 
                    onChange={handleChange} 
                    placeholder="Ссылка на фото"
                />
                <input 
                    name="price" 
                    value={itemData.price} 
                    onChange={handleChange} 
                    placeholder="Цена"
                />
                <input 
                    name="buy_link" 
                    value={itemData.buy_link} 
                    onChange={handleChange} 
                    placeholder="Ссылка на магазин"
                />
                <button type="submit">Добвить</button>
                {formError && <div className="error-message">{formError}</div>}
            </form>
        </div>
    );
}

export default AddItemForm;
