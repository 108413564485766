import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './components/Main';
import Admin from './components/Admin';
import LoginForm from './components/LoginForm'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginForm} />
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;

