import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Wishlist from './Wishlist';
import AddItemForm from './AddItemForm';
import api from '../api';
import { getOrCreateUserId } from '../utils/cookieUtils';

function Admin() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [items, setItems] = useState([]); // State for storing the list of items
    const history = useHistory();
    const userId = getOrCreateUserId();

    // Function to fetch items
  const fetchItems = async () => {
    try {
      const fetchedItems = await api.fetchWishlistItems(userId);
      setItems(fetchedItems);
    } catch (err) {
      // Handle error in fetching items
      console.error('Ошибка при загрузке вишлиста: ', err);
    }
  };

  // Function to be called when an item is added
  const onItemAdded = () => {
    fetchItems(); // Refresh the list of items
  };

  // onAction function to be called when an action is performed in WishlistItem
  const onAction = () => {
      fetchItems(); // Re-fetch items to update the UI
  };

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await api.checkAuth(); // Your API call to check authentication
                if (!response.ok) {
                    history.push('/login');
                } else {
                    setLoggedIn(true);
                    fetchItems();
                }
            } catch (error) {
                history.push('/login');
            }
        };

        checkAuthStatus();
    }, [history]);

    if (!loggedIn) {
        return null; // Or a loading spinner, etc.
    }

    return (
      <>
        <div className="main-container admin-panel">
            <h1>Добавить желание</h1>
            <AddItemForm onItemAdded={onItemAdded} />
        </div>
        <Wishlist items={items} onAction={onAction} isAdmin={true} userId={userId} editable />
      </>
    );
}

export default Admin;
