const API_BASE_URL = 'https://wishlist.dezdichado.com/api';

const getToken = () => localStorage.getItem('token');

const api = {
  login: async (credentials) => {
    const response = await fetch(`${API_BASE_URL}/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(credentials)
    });
    const data = await response.json();
    if (data.access_token) {
      localStorage.setItem('token', data.access_token);
    }
    return data;
  },
  checkAuth: async () => {
    const response = await fetch(`${API_BASE_URL}/checkAuth`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getToken()}` },
    });
    return response.json();
  },
  fetchWishlistItems: async (userId) => {
    const response = await fetch(`${API_BASE_URL}/wishlist?user_id=${userId}`);
    return response.json();
  },
  addItem: async (item) => {
    const response = await fetch(`${API_BASE_URL}/admin/wishlist`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getToken()}` },
      body: JSON.stringify(item),
    });
    return response.json();
  },
  updateItem: async (itemId, item) => {
    const response = await fetch(`${API_BASE_URL}/admin/wishlist/${itemId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getToken()}` },
      body: JSON.stringify(item),
    });
    return response.json();
  },

  deleteItem: async (itemId) => {
    const response = await fetch(`${API_BASE_URL}/admin/wishlist/${itemId}`, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${getToken()}` },
    });
    return true;
  },

  reserveItem: async (itemId, userId) => {
    const response = await fetch(`${API_BASE_URL}/wishlist/reserve/${itemId}?user_id=${userId}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${getToken()}` },
    });
    return response.json();
  },

  cancelReserveItem: async (itemId, userId) => {
    const response = await fetch(`${API_BASE_URL}/wishlist/cancel-reserve/${itemId}?user_id=${userId}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${getToken()}` },
    });
    return response.json();
  },
};

export default api;
