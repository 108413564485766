import React, { useState } from 'react';
import api from '../api';

function WishlistItem({ item, onAction, isAdmin, userId }) {
  const canCancel = isAdmin ? true : item.can_cancel;
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState({ ...item });  // Initialize editedItem state
  
  const handleReserve = async () => {
    try {
      await api.reserveItem(item.id, userId);
      onAction();
    } catch (err) {
      alert('Не получилось забронировать\n\n' + err);
    }
  };

  const handleCancelReserve = async () => {
    try {
      await api.cancelReserveItem(item.id, userId);
      onAction();
    } catch (err) {
      alert('Не получилось отменить бронь\n\n' + err);
    }
  };

  const handleEdit = () => {
      // Toggle editing mode
      setIsEditing(!isEditing);
    };

    const handleDelete = async () => {
      try {
        // Call API to delete the item
        await api.deleteItem(item.id);
        onAction(); // Refresh the items in the parent component
      } catch (err) {
        alert('Не получилось удалить\n\n' + err);
      }
    };

    const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedItem(prevState => ({ ...prevState, [name]: value }));  // Correctly update editedItem state
  };

  const handleUpdateItem = async (e) => {
    e.preventDefault();
    try {
      await api.updateItem(item.id, editedItem);
      setIsEditing(false); // Exit editing mode on success
      onAction(); // Refresh the items in the parent component
    } catch (err) {
      alert('Не получилось обновить\n\n' + err);
    }
  };

  const renderEditFields = () => {
    return (
      <div className="add-item-form">
        <form onSubmit={handleUpdateItem}>
          <input
            type="text"
            name="name"
            value={editedItem.name}
            onChange={handleEditChange}
            placeholder="Название"
          />
          <input
            type="text"
            name="description"
            value={editedItem.description}
            onChange={handleEditChange}
            placeholder="Описание"
          />
          <input 
              name="picture_url" 
              value={editedItem.picture_url} 
              onChange={handleEditChange} 
              placeholder="Ссылка на фото"
          />
          <input
            name="price"
            value={editedItem.price}
            onChange={handleEditChange}
            placeholder="Цена"
          />
          <input
            type="text"
            name="buy_link"
            value={editedItem.buy_link}
            onChange={handleEditChange}
            placeholder="Ссылка на магазин"
          />
          <button type="submit">Обновить</button>
          <button type="button" className="cancel-button" onClick={() => setIsEditing(false)}>Отмена</button>
        </form>
      </div>
    );
  };

  const renderAdminActions = () => {
    return (
          <div className="item-actions">
            <span className="edit-icon" onClick={handleEdit}>📝</span>
            <span className="delete-icon" onClick={handleDelete}>🗑️</span>
          </div>
      );
  };

  return (
    <div className={`wishlist-item ${item.reserved ? 'reserved' : ''}`}>
      {item.picture_url && <img src={item.picture_url} alt={item.name} />}
      <div className="wishlist-item-content">
        {isEditing ? (
          renderEditFields()
        ) : (
          <>
            <h3>{item.name}</h3>
            {item.description && <p>{item.description}</p>}
            {(item.price || item.buy_link) && <p>{item.price} {item.buy_link && <a href={item.buy_link}>ссылка</a>}</p>}
          </>
        )}
      </div>
      <div>
        {!item.reserved ? (
          <button onClick={handleReserve}>Забронировать</button>
        ) : canCancel ? (
          <button onClick={handleCancelReserve}>Отменить бронь</button>
        ) : (
          <div className="reserved-label">Забронировано</div>
        )}
        {isAdmin && renderAdminActions()}
    </div>

    </div>
  );
}

export default WishlistItem;
