import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export function getOrCreateUserId() {
  let userId = Cookies.get('userId');
  if (!userId) {
    userId = generateUniqueId(); // Implement this function to generate a unique ID
  }
  const veryLongTime = new Date();
  veryLongTime.setFullYear(veryLongTime.getFullYear() + 100); // Set the cookie to expire in 100 years
  Cookies.set('userId', userId, { expires: veryLongTime });
  return userId;
}

function generateUniqueId() {
  return uuidv4();
}
