import WishlistItem from './WishlistItem';

function Wishlist({ items, onAction, isAdmin, userId }) {

    return (
        <div className="main-container">
            <h1>Мой вишлист</h1>
            {items.map(item => (
                <WishlistItem key={item.id} item={item} onAction={onAction} isAdmin={isAdmin} userId={userId} />
            ))}
        </div>
    );
}

export default Wishlist;
